import "./App.css";
import Form from "./components/Form";

function App(){
  return (
    <>
      <Form></Form>
    </>
  )
}

export default App;